//react libraries import
import React from 'react';

//custom components/libraries import

const ContactusPage = () => {

    const handleFormSubmit = (data) => {
        console.log(JSON.stringify(data));
        alert('submitted');
    }

    return (
        <main className="px-2 md:px-12 my-4">
            {/** Location Info */}
            <h3 className="my-6 uppercase text-center text-2xl text-primary font-bold">Have Some Questions?</h3>
            
            <div className="flex flex-col md:flex-row px-4 md:px-24 py-4 items-center md:items-start">
                <span className="w-full md:w-1/2">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-full w-full" fill="none" viewBox="0 0 24 24" stroke="#b0a7a8">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
                    </svg>
                </span>

                {/** Contact Form */}
                <div className="flex flex-col bg-blue shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-md w-full md:w-1/2">
                    <div className="self-center text-base sm:text-2xl uppercase text-white font-bold">Contact Form</div>
                    <div className="mt-10">
                        <form onSubmit={handleFormSubmit}>
                            <div className="flex flex-col mb-6">
                                <label for="name" className="mb-1 text-xs sm:text-sm tracking-wide text-white">Name (required)</label>
                                <div className="relative">
                                    <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                                        <span>
                                            <svg className="h-6 w-6" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="#556e86">
                                                <path d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                                            </svg>
                                        </span>
                                    </div>

                                    <input id="name" type="text" name="name" className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400" placeholder="Name" />
                                </div>
                            </div>
                            <div className="flex flex-col mb-6">
                                <label for="email" className="mb-1 text-xs sm:text-sm tracking-wide text-white">E-Mail Address:</label>
                                <div className="relative">
                                    <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="#556e86">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                                            </svg>
                                        </span>
                                    </div>

                                    <input id="email" type="email" name="email" className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400" placeholder="E-Mail Address" />
                                </div>
                            </div>
                            <div className="flex flex-col mb-6">
                                <label for="subject" className="mb-1 text-xs sm:text-sm tracking-wide text-white">Subject</label>
                                <div className="relative">
                                    <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                                        <span>
                                            <svg className="h-6 w-6" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="#556e86">
                                                <path d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                                            </svg>
                                        </span>
                                    </div>

                                    <input id="subject" type="text" name="subject" className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400" placeholder="Subject" />
                                </div>
                            </div>


                            <div className="flex flex-col mb-6">
                                <label for="subject" className="mb-1 text-xs sm:text-sm tracking-wide text-white">Subject</label>
                                <div className="relative">
                                    <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                                        <span>
                                            <svg className="h-6 w-6" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="#556e86">
                                                <path d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                                            </svg>
                                        </span>
                                    </div>

                                    <input id="subject" type="text" name="subject" className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400" placeholder="Subject" />
                                </div>
                            </div>

                            <div className="flex w-full">
                            <button type="submit" className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-fourth hover:bg-blue-700 rounded py-2 w-full transition duration-150 ease-in">
                                <span className="mr-2 uppercase">Submit</span>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="white">
                                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13a1 1 0 102 0V9.414l1.293 1.293a1 1 0 001.414-1.414z" clipRule="evenodd" />
                                    </svg>
                                </span>
                            </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/** Location Info with Map */}
            <div className="flex flex-col md:flex-row items-start mt-4 mb-8 px-3 md:px-8">
                <div className="my-2 px-0 md:px-2 w-full md:w-8/12 overflow-hidden">
                    <div className="text-center md:text-left text-lg text-primary font-bold mt-8 mb-7 underline">Find us here</div>
                    
                    
                    <iframe  src="https://www.youtube-nocookie.com/embed/FMrtSHAAPhM"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3512.8608610413376!2d83.92821371445838!3d28.30253870569145!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3995ecd51be6d9e9%3A0xdac037dc6330b801!2sShree%20Mahendra%20Secondary%20School%2C%20Lahachowk!5e0!3m2!1sen!2snp!4v1632123854993!5m2!1sen!2snp" 
                        width="700"
                        height="525"
                        style={{ border: 0 }}
                        title="School Map"
                        allowfullscreen=""
                        className="hidden md:block mx-auto md:mx-0 rounded-md" 
                        loading="lazy"
                    ></iframe>



                    {/** Iframe for mobile with decreased height and width */}
                    <iframe  src="https://www.youtube-nocookie.com/embed/FMrtSHAAPhM"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3512.8608610413376!2d83.92821371445838!3d28.30253870569145!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3995ecd51be6d9e9%3A0xdac037dc6330b801!2sShree%20Mahendra%20Secondary%20School%2C%20Lahachowk!5e0!3m2!1sen!2snp!4v1632123854993!5m2!1sen!2snp" 
                        width="360"
                        height="380"
                        style={{ border: 0 }}
                        title="School Map"
                        allowfullscreen=""
                        className="block md:hidden mx-auto md:mx-0 rounded-md" 
                        loading="lazy"
                    ></iframe>


                </div>


                <div className="flex flex-col my-4 md:my-20 mx-auto md:-mx-4">
                    <div className="my-3 px-3 w-full overflow-hidden bg-extra-light-primary py-3 rounded-md">
                        <div className="flex flex-col">
                            <div className="inline-flex text-base text-primary font-semibold">
                                <span className="mr-3">
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        className="h-5 w-5" 
                                        fill="none" 
                                        viewBox="0 0 24 24" 
                                        stroke="#e49d22"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                                    </svg>
                                </span>
                                Contact No
                            </div>
                            <p className="my-2 text-blue ml-4">061-621313</p>
                        </div>
                    </div>

                    <div className="my-3 px-3 w-full overflow-hidden bg-extra-light-primary py-3 rounded-md">
                        <div className="flex flex-col">
                            <div className="inline-flex text-base text-primary font-semibold">
                                <span className="mr-3">
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        className="h-5 w-5" 
                                        fill="none" 
                                        viewBox="0 0 24 24" 
                                        stroke="#e49d22"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                                    </svg>
                                </span>
                                Email
                            </div>
                            <p className="my-2 break-all text-blue ml-4">shreemahindra.mabi@gmail.com</p>
                        </div>
                    </div>

                    <div className="my-3 px-3 w-full overflow-hidden bg-extra-light-primary py-3 rounded-md">
                        <div className="flex flex-col">
                            <div className="inline-flex text-base text-primary font-semibold">
                                <span className="mr-3">
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        className="h-5 w-5" 
                                        fill="none" 
                                        viewBox="0 0 24 24" 
                                        stroke="#e49d22"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>
                                </span>
                                Address
                            </div>
                            <p className="my-2 text-blue ml-4">Machhapuchchhre, Lahachok-4, Kaski</p>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default ContactusPage;